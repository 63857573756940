<script lang="ts" setup>
const props = defineProps<{ opened: number }>()

const emit = defineEmits<{
  'update:opened': [number]
}>()

function itemClickHandler(idx: number): void {
  emit('update:opened', idx !== props.opened ? idx : -1)
}
</script>

<template>
  <ul>
    <slot :active-idx="opened" :on="{ 'click:header': itemClickHandler }" />
  </ul>
</template>
